.change-pass-modal {
    .error-change-pass {
        margin-bottom: 16px;
    }

    .change-pass-oldPass {
        height: 80px;
    }

    .change-pass-require {
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 9px;
        line-height: 18px;
        color: #7a829e;

        p {
            margin-bottom: 0;
        }
    }

    .change-pass-repeatNewPass {
        height: 80px;
    }
}
