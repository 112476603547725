.app-layout {
    flex-direction: row !important;
    height: 100%;

    .layout-content {
        background: #f8f9fc;
    }

    div[style] {
        .v-scroll-track,
        .h-scroll-track {
            opacity: 0;
            transition: opacity 0.2s ease;
            z-index: 101;
        }

        .v-scroll-track {
            background-color: #f2f2f2;
            height: calc(100% - 32px);
            right: 0;

            .v-scroll-thumb {
                background-color: #bbb;
                border-radius: 3px;
            }
        }

        .h-scroll-track {
            background-color: #f2f2f2;
            width: calc(100% - 16px);
            bottom: 0;

            .h-scroll-thumb {
                background-color: #bbb;
                border-radius: 3px;
            }
        }

        &:hover {
            .v-scroll-track,
            .h-scroll-track {
                opacity: 1;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .layout-content {
        margin-top: 50px;
    }
}
