.app-switchselect {
    display: flex;
    flex-direction: column;

    &.disabled {
        .switchselect-wrapper {
            .switchselect-button {
                cursor: not-allowed !important;
                color: #c4c8d4;

                &.active {
                    background: #c4c8d4 !important;
                }
            }
        }
    }

    .switchselect-label {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #7a829e;
        padding-bottom: 4px;
    }

    .switchselect-wrapper {
        height: 36px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #f1f3f8;
        color: #7a829e;
        border-radius: 4px;

        .switchselect-button {
            height: 100%;
            padding-left: 6px;
            padding-right: 6px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &.active {
                color: #ffffff;
                background: #157ffb;
            }
        }
    }
}
