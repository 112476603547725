.app-sidebar {
    background: var(--c-platinate-purple) !important;
    color: white;
    width: 208px;
    overflow: auto;
    height: 100vh !important;

    .profile-exit {
        color: var(--c-bone);
        margin: 10px auto;
        width: 150px;
        svg {
            vertical-align: middle;
            margin-right: 5px;
        }
    }

    .sidebar-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;

        .sidebar-top {
            height: 100%;
            overflow-y: hidden;
            overflow-x: hidden;
            display: flex;
            flex-direction: column;

            .sidebar-cube {
                margin: 0 auto;
            }

            .sidebar-logo {
                margin: 20px auto;
            }

            .sidebar-buttons {
                height: 100%;
                overflow-y: hidden;
                overflow-x: hidden;
                display: flex;

                .chapter {
                    padding: 0 16px;

                    .active {
                        margin: 0px -9px;
                        padding-left: 9px;
                        border-radius: 6px;
                        background: #96a8d633;
                        color: rgba(255, 255, 255, 0.85) !important;
                    }

                    .chapter-name {
                        margin-top: 24px;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 20px;
                        color: rgba(255, 255, 255, 0.3);
                    }

                    .button {
                        display: flex;
                        align-items: center;
                        height: 40px;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 22px;
                        color: rgba(255, 255, 255, 0.65);

                        .button-icon {
                            display: flex;
                            align-items: center;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        .sidebar-user {
            padding: 9px;
            display: flex;
            align-items: center;
            border-radius: 6px;
            margin: 9px;

            &.active-profile {
                background: #96a8d633;
            }

            .avatar {
                background-color: #9508d7;
                min-width: 32px;
            }

            .user-wrapper {
                display: flex;
                flex-direction: column;
                margin-left: 10px;

                .name {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    color: rgba(255, 255, 255, 0.65);
                }

                .job {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    color: rgba(255, 255, 255, 0.45);
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .app-sidebar {
        display: none;
    }
}
