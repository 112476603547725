.app-tag {
    span {
        display: flex;
        align-items: center;
        min-width: min-content;
        max-width: min-content;
        border-radius: 100px;
        border: 0px;
        height: 26px;
    }

    .tag-success {
        background: #e6fffb;
        color: #08979c;
    }

    .tag-error {
        background: #fff0f3;
        color: #f52245;
    }

    .tag-default {
        background: #f5222d;
        color: #ffffff;
    }

    .tag-warning {
        background: #fff7e6;
        color: #ff8200;
    }

    .tag-processing {
        background: #f0f5ff;
        color: #2f54eb;
    }
}

@media screen and (max-width: 768px) {
    .app-tag {
        .ant-tag {
            border-radius: 4px;
            font-size: 14px;
        }
    }
}
