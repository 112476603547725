.app-input {
    display: flex;
    flex-direction: column;
    min-height: 75px;

    .ant-radio-wrapper {
        margin-bottom: 12px;
    }

    .input-textarea-bottom {
        display: flex;
        justify-content: space-between;

        .input-textarea-countLength {
            font-weight: 500;
            font-size: 10px;
            line-height: 18px;
            color: #c4c8d4;
        }
    }

    .input-search {
        display: flex;
        align-items: center;
        max-width: 500px;
        max-height: 38px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #cfd5e5;

        &:hover {
            border: 1px solid var(--c-blue);
        }

        &:focus-within {
            border: 1px solid var(--c-blue);
        }

        .ant-select,
        .ant-select-focused {
            width: 100%;
            max-width: 130px;
            margin-left: 2px;

            div {
                display: flex;
                align-items: center;
                background: #f1f3f8;
                border-radius: 4px;
                border: 0 !important;
                box-shadow: none !important;
                color: #7a829e;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
            }
        }

        .ant-input-affix-wrapper,
        .ant-input-affix-wrapper-focused {
            max-width: 325px;
            max-height: 36px;
            border: 0;
            padding: 0 11px 0 11px;
            box-shadow: none;
        }

        .search-button {
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #cfd5e5;
            padding: 7px;
            width: 100%;
            max-width: 42px;
        }

        .input-search-select {
            min-width: 35%;
        }
    }

    .label {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #7a889e;
        padding-bottom: 4px;
    }

    .text-error {
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
        color: #f52245;
    }

    .form {
        width: 100%;
    }

    .input {
        width: 100%;
        min-height: 35px;
        background: #ffffff;
        border: 1px solid #d4d9e0;
        border-radius: 4px;
    }

    .input-error {
        background: #fff0f3;
        border: 1px solid #ff9eae;

        input {
            background: #fff0f3;
        }
    }

    .input-success {
        background: #edfff8;
        border: 1px solid #8febc5;

        input {
            background: #edfff8;
        }
    }
}

@media screen and (max-width: 915px) {
    .app-input {
        .input {
            padding: 0 16px;
            overflow: hidden;
            display: flex;
            align-items: center;
        }
    }
}
