.photo-wrapper {
    position: relative;
    width: 150px;
    height: 100px;

    &.click-photo {
        cursor: pointer;
    }

    .photo-remove {
        background: #ffffff;
        border-radius: 100px;
        width: 24px;
        height: 24px;
        position: absolute;
        z-index: 2;
        left: 75%;
        margin: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .photo-img {
        width: 150px;
        height: 100px;
        position: absolute;
        z-index: 1;
        border-radius: 4px;
    }
}
