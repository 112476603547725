.app-table {
    .ant-checkbox-wrapper {
        margin-left: -9px;
    }

    .ant-table-thead tr .ant-table-cell {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #7a829e;
        background: #ebeef5;
        align-items: center;

        :first-child &:before {
            display: none;
        }
    }

    .ant-table-thead tr {
        .ant-table-cell:first-child {
            padding-left: 20px !important;
        }
    }

    .ant-table-tbody tr {
        .ant-table-cell:first-child {
            padding-left: 20px !important;
        }
    }

    .ant-table-row {
        .ant-table-cell {
            vertical-align: middle;
        }

        .ant-table-cell-row-hover {
            background: #ecf5ff !important;
        }

        &:focus-within {
            background: #ecf5ff !important;
        }
    }

    .table-skeleton {
        .ant-skeleton-content {
            h3 {
                width: 100% !important;
                margin-top: 12px !important;
                margin-bottom: 12px !important;
            }

            .ant-skeleton-paragraph {
                display: none;
            }
        }
    }
}
