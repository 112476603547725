.events-table {
    .fio {
        display: flex;
        flex-direction: column;
    }

    .event-table-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .events-table-jobs {
        display: flex;
        flex-direction: column;

        .events-table-jobs-name {
            display: flex;
            flex-direction: row;
            align-content: center;
            gap: 4px;

            .events-table-jobs-over {
                display: flex;
                align-items: center;
                color: #157ffb;
            }
        }

        .events-table-jobs-partner {
            color: #7a829e;
        }
    }

    .contacts {
        display: flex;
        flex-direction: column;

        .contacts-email {
            display: flex;
            align-items: center;

            .email-icon {
                margin-right: 6px;
            }
        }

        .contacts-phone {
            display: flex;
            align-items: center;

            .phone-icon {
                margin-right: 6px;
            }
        }
    }

    .action {
        color: var(--c-blue);
    }

    .dropdown {
        color: var(--v-vivit-violet);
        user-select: none;
        cursor: pointer;
    }
}

.events-table-tooltip {
    display: flex;
    flex-direction: column;
}

.ant-picker-dropdown {
    button {
        color: aliceblue;
    }
}
