.login-footer {
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #131629 !important;

    .footer-logo {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #ffffffd9;

        .logo {
            display: flex;
            margin-right: 13px;
        }

        p {
            margin-bottom: 0 !important;
        }
    }

    .footer-copyright {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #7a889e;
    }
}
