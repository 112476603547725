.app-inner-block {
    background: #ffffff;
    border: 0.5px solid #f1f3f8;
    border-radius: 4px;
    padding: 4px 14px;

    .price-wrapper {
        display: flex;
        flex-wrap: wrap;
        column-gap: 50px;
    }

    .user-images-wrapper {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
    }

    .app-inner-block-title {
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        color: #4b5167;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .app-inner-block-title-text {
            display: flex;
            align-items: baseline;
            flex-direction: row;
            gap: 12px;

            .app-inner-block-text-descritption {
                color: #b8bdcc;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
            }
        }

        .app-inner-block-text-button {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: var(--c-blue);
        }

        .app-inner-block-button {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #fff;
        }
        .header-total {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #b8bdcc;
            margin-left: 12px;
            margin-top: 5px;
        }
    }
    .title {
        display: flex;
    }
}
