.page-login {
    font-family: 'TTFirsNeueRegular' !important;
    display: flex;
    .login-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .login-main {
            height: 90%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }

        .login-copyright {
            margin-top: 134px;
            margin-bottom: 20px;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #7a829e;
        }

        .app-alert {
            margin-top: 60px;
            max-width: 368px;
        }

        .big-logo {
            margin-bottom: 30px;
        }
        .form {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 368px;

            .form-logo {
                display: none;
            }

            .form-title {
                display: flex;
                justify-content: center;
                font-size: 28px;
                font-weight: 800;
                line-height: 36px;
                letter-spacing: 0em;
                text-align: center;
            }

            .form-subtitle {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #4b5167;
                text-align: center;
                margin-bottom: 40px;
            }

            .form-login {
                width: 100%;
            }

            .form-password {
                width: 100%;
                margin-top: 20px;
            }

            .form-email {
                width: 100%;
            }

            .form-button {
                margin: 40px 0px 20px 0px;
            }
        }
    }

    .login-image {
        width: 30%;
        height: 100vh;
        right: 0;

        .login-image-logo {
            height: 100vh;
            z-index: 1;
            position: absolute;
            width: inherit;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .login-image-text {
            font-weight: 400;
            font-size: 24px;
            line-height: 219.34%;
            color: rgba(255, 255, 255, 0.5);

            z-index: 2;
            position: absolute;

            display: flex;
            flex-direction: column;
            top: 40%;
            padding-left: 2%;
        }
    }
}

@media screen and (max-width: 915px) {
    .page-login {
        .login-image {
            display: none;
        }

        .login-form {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
            justify-content: flex-start;

            .login-main {
                width: 100%;
                justify-content: flex-start;
            }

            .form {
                width: 100%;

                .form-logo {
                    margin-bottom: 32px;
                    width: 100%;
                    padding: 13px 0;
                    display: flex;
                    justify-content: center;
                }

                .form-title {
                    margin-bottom: 41px;
                    font-size: 22px;
                    line-height: 30px;
                }

                .form-title-reset {
                    margin-bottom: 12px;
                }

                .form-subtitle {
                    font-size: 16px;
                    margin-bottom: 35px;
                }

                .form-subtitle-reseted {
                    margin-bottom: 0;
                }

                .form-login,
                .form-password {
                    .label {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }

                input {
                    font-size: 16px;
                    line-height: 22px;
                    height: 50px;
                }

                .wide.form-button {
                    width: 100% !important;
                    margin-bottom: 24px;
                    font-size: 16px;
                    line-height: 22px;
                    height: 50px !important;
                }

                .wide.form-button-alert {
                    margin-top: 16px;
                    order: 1;
                }

                .wide.form-button-reset {
                    margin-bottom: 16px;
                }

                .text-button {
                    font-size: 16px;
                    line-height: 22px;
                    color: #7a829e;
                }

                .text-button-alert {
                    order: 1;
                }

                .text-error {
                    //
                    font-size: 10px;
                    line-height: 18px;
                }

                .app-alert {
                    margin-top: 40px;
                }
            }
        }
    }
}

.container {
    color: var(--color);
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
}

.right {
    text-align: right;
    width: 100%;
}

.stack {
    display: grid;
    grid-template-columns: 1fr;
}

.stack span {
    font-weight: bold;
    grid-row-start: 1;
    grid-column-start: 1;
    font-size: 4rem;
    --stack-height: calc(100% / var(--stacks) - 1px);
    --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
    --clip-top: calc(var(--stack-height) * var(--index));
    --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
    clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
    animation: stack 340ms cubic-bezier(0.46, 0.29, 0, 1.24) 1 backwards calc(var(--index) * 120ms),
        glitch 2s ease infinite 2s alternate-reverse;
}

.stack span:nth-child(odd) {
    --glitch-translate: 8px;
}
.stack span:nth-child(even) {
    --glitch-translate: -8px;
}

@keyframes stack {
    0% {
        opacity: 0;
        transform: translateX(-50%);
        text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    }
    60% {
        opacity: 0.5;
        transform: translateX(50%);
    }
    80% {
        transform: none;
        opacity: 1;
        text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
    }
    100% {
        text-shadow: none;
    }
}

@keyframes glitch {
    0% {
        text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
        transform: translate(var(--glitch-translate));
    }
    2% {
        text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
    }
    4%,
    100% {
        text-shadow: none;
        transform: none;
    }
}
