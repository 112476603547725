.app-select {
    display: flex;
    flex-direction: column;
    min-height: 77px;

    .ant-skeleton {
        h3 {
            width: 100% !important;
            height: 30px !important;
        }
    }

    .select {
        display: flex;
        align-items: center;
        background: #fff0f3;

        &.select-error > * {
            background: #fff0f3 !important;
        }

        &.select-success > * {
            background: #edfff8 !important;
        }

        &.select-tags {
            .ant-select-selection-item {
                background: #f1f3f8;
                border-radius: 4px;
                padding: 2px 8px;
                padding: 2px 8px;
                display: flex;
                align-items: center;
            }
        }

        .ant-select-selector {
            height: 100% !important;
            width: 100% !important;
            border: 0 !important;
            display: flex;
            align-items: center;
        }
    }

    .label {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #7a889e;
        padding-bottom: 4px;
    }

    .text-error {
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
        color: #f52245;
    }

    .text-warn {
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
        color: #ff8200;
    }

    .select {
        width: 100%;
        min-height: 35px;
        background: #ffffff;
        border: 1px solid #d4d9e0;
        border-radius: 4px;
    }

    .select-error {
        background: #fff0f3;
        border: 1px solid #ff9eae;

        input {
            background: #fff0f3;
        }
    }

    .select-success {
        background: #edfff8;
        border: 1px solid #8febc5;

        input {
            background: #edfff8;
        }
    }

    .text-select {
        background: transparent;
        border: 0 !important;
        border-radius: 0;

        .ant-select-selector {
            background-color: transparent !important;
            border: 0;
            border-radius: 0;
        }
    }
}

.space-selectInSearch {
    padding: 4px 12px 8px 12px;
    width: 100%;

    .select-selectInSearch {
        width: 100%;
        background: #f8f9fc !important;
        border: 1px solid #cfd5e5 !important;
        border-radius: 4px !important;

        input {
            background: #f8f9fc !important;
        }
    }
}

.space-checkboxInSelect {
    display: flex;
    flex-direction: column;

    .checkboxInSelect {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 300px;
        min-height: 30px;
    }

    .ant-checkbox-wrapper {
        margin-left: 0px !important;
    }

    .ant-divider {
        margin: 6px 0 2px 0 !important;
    }

    .checkbox-checkboxInSelect {
        padding: 5px 5px 5px 10px;
        width: 100%;

        &:hover {
            background: #ecf5ff;
        }

        .checkbox-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 6px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;

            .checkbox-title-descritpion {
                color: #7a829e;
            }

            .checkbox-title-types {
                display: flex;
                flex-direction: row;
                align-items: center;

                .ant-tag {
                    border-radius: 4px;
                }

                .tag-processing {
                    background: #fcf0ff;
                    color: #ce2ed1;
                }

                .tag-success {
                    background: #f9f0ff;
                    color: #722ed1;
                }
            }
        }
    }
}

.select-emptySelect {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 45px 0;

    .emptySelect-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #7a829e;
    }
}

.select-spin-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
}

.select-switchSelect-wrapper {
    margin: 6px 11px;
    .select-switchSelect {
        width: 100%;
        max-width: 100% !important;
    }
}

.select-createNew-wrapper {
    border-top: 1px solid #f1f3f8;
    padding: 8px;
    margin-top: 2px;
}

// .select-custom-values {
//     padding: 5px 5px 5px 10px;
//     width: 100%;

//     &:hover {
//         background: #ECF5FF;
//     }

.select-custom-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    .select-custom-title-descritpion {
        color: #7a829e;
    }

    .select-custom-title-types {
        display: flex;
        flex-direction: row;
        align-items: center;

        .ant-tag {
            border-radius: 4px;
        }

        .tag-processing {
            background: #fcf0ff;
            color: #ce2ed1;
        }

        .tag-success {
            background: #f9f0ff;
            color: #722ed1;
        }
    }
}

.ant-select-selection-item {
    .select-custom-title {
        display: flex;
        flex-direction: row;
        gap: 11px;
    }

    .select-custom-title-types {
        display: none;
    }

    &:after {
        content: none !important;
    }
}
