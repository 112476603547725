.app-alert {
    border-radius: 4px !important;
    width: 100%;

    .alert-error {
        background: #fff0f3 !important;
        border: 1px solid #ff9eae !important;
        border-radius: 4px;

        .ant-alert-message {
            color: #f52245;
        }
    }

    .alert-warning {
        background: #fff7e6 !important;
        border: 1px solid #ff8200 !important;
        border-radius: 4px;

        .ant-alert-message {
            color: #ff8200;
        }
    }

    .alert-info {
        background: #f0f5ff !important;
        border: 0 !important;
        border-radius: 4px;

        .ant-alert-message {
            color: #2f54eb;
        }
    }
}
