.app-mobile-header {
    display: none;
}

@media screen and (max-width: 768px) {
    .app-mobile-header {
        height: 50px !important;
        left: 0;
        right: 0;
        position: fixed;
        top: 0;
        background: #fff !important;
        padding-left: 17px !important;
        padding-right: 17px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ebeef5;
        z-index: 3;

        .app-mobile-header-icon-logo {
            height: 100%;
            display: flex;
            align-items: center;

            path {
                fill: #157ffb;
                fill-opacity: 1;
            }
        }

        .app-mobile-header-icon-menu {
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;

            .app-mobile-header-icon-close svg {
                display: flex;
                width: 16px;
                height: 16px;
            }
        }
    }

    .app-mobile-menu {
        position: absolute;
        padding-top: 16px;
        left: 0;
        right: 0;
        top: 50px;
        height: calc(100vh - 50px);
        background-color: #fff;
        z-index: 1;
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
        overflow-x: hidden;

        .button {
            text-decoration: none;
            display: flex;
            height: 62px;
            padding-left: 18px;
            align-items: center;
            font-size: 16px;
            line-height: 22px;
            color: #000;
            font-weight: 500;

            .button-icon {
                margin-right: 24px;
                display: flex;

                svg {
                    width: 20px;
                    height: 20px;
                    stroke-opacity: 1;

                    path {
                        fill: #157ffb;
                        fill-opacity: 1;
                    }
                }
            }

            &.active,
            &:hover {
                color: #157ffb;
            }
        }

        .topbar-profile-wrapper {
            padding: 35px 16px 20px;

            .topbar-user {
                background-color: orange;
                display: flex;
                height: 76px;
                background: #f8f9fc;
                border-radius: 8px;
                padding: 16px;
                align-items: center;
                justify-content: space-between;
                text-decoration: none;

                .avatar {
                    width: 35px;
                    height: 35px;
                    background-color: #fbb100;
                    font-size: 20px;
                    font-weight: 500;
                    margin-right: 12px;
                }

                .user-wrapper {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    justify-content: space-between;

                    .name {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 22px;
                        color: #4b5167;
                    }

                    .job {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 22px;
                        color: #7a829e;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 415px) {
    .app-mobile-menu .topbar-profile-wrapper {
        padding-bottom: 100px;
    }
}
