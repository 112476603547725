.app-pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    ul {
        display: flex;
        li {
            display: flex;
            align-items: center;
        }
    }

    .disabled {
        color: #c4c8d4 !important;

        .icon-next {
            svg {
                * {
                    fill: #c4c8d4;
                }
            }
        }

        .icon-prev {
            svg {
                * {
                    fill: #c4c8d4;
                }
            }
        }
    }

    .pagination-prev {
        display: flex;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #7a829e;
        cursor: pointer;
        margin-right: 16px;

        .icon-prev {
            margin-right: 5px;
        }
    }

    .pagination-next {
        display: flex;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #7a829e;
        cursor: pointer;
        margin-left: 16px;

        .icon-next {
            margin-left: 5px;
        }
    }

    .ant-pagination-options {
        display: none;
    }

    .pagination-total {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #7a829e;
    }

    .ant-pagination-item a {
        color: #7a829e;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;

        &:hover {
            color: var(--c-blue);
        }
    }

    .ant-pagination-item-active {
        background: #ecf5ff;
        border: 1px solid var(--c-blue) !important;

        a {
            color: var(--c-blue) !important;
        }
    }
}
