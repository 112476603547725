.app-infoModal {
    .ant-modal-footer,
    .ant-modal-close {
        display: none;
    }

    .infoModal-conent {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 10px;

        .infoModal-info {
            display: flex;
            flex-direction: row;
            gap: 17px;

            .infoModal-icon {
                margin-top: 2px;
            }

            .infoModal-text {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .infoModal-title {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 28px;
                    color: #1e253e;
                }

                .infoModal-message {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    color: #1e253e;
                }
            }
        }

        .infoModal-buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: 12px;
        }
    }
}
