.screen-error {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .screen-error-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 140px;

        .error-logo {
            position: absolute;
        }

        .error-logo-text {
            position: absolute;
            min-width: max-content;
            font-weight: 800;
            font-size: 60px;
            line-height: 72px;
            color: #1e253e;
        }
    }

    .screen-error-text {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #7a829e;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}
