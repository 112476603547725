.app-profile-title {
    background-color: #ffffff;
    border: 0.5px solid #f1f3f8;
    border-radius: 8px;
    padding: 24px;
    margin: 24px;
    height: 155px;
    display: flex;
    gap: 20px;

    .title-avatar {
        display: flex;
        align-items: center;

        .ant-avatar {
            background-color: #9508d7;
        }
    }

    .title-info {
        .info-id {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #7a829e;
            margin-bottom: 10px;
        }

        .info-name {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #1e253e;
        }

        .info-role {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #7a829e;
        }
    }

    .title-skeleton {
        .ant-skeleton-avatar {
            width: 107px;
            height: 107px;
        }

        .ant-skeleton-title {
            width: 10% !important;
        }

        .ant-skeleton-paragraph {
            width: 50%;
            margin-top: 15px;
        }
    }
}
