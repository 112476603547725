.app-skeleton {
    &.short {
        max-width: 200px;
    }

    &.mainCorpClientInfo-info-block {
        max-width: 220px;
        height: 28px;
    }
    &.corp-client {
        max-width: 600px;
        height: 22px;
        margin-bottom: 10px;
    }
    .ant-skeleton-content {
        h3 {
            margin-top: 0px !important;
            margin-bottom: 0px !important;
        }

        .ant-skeleton-paragraph {
            display: none;
        }
    }
}
