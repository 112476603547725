@import 'antd/dist/reset.css';

@font-face {
    font-family: 'IBMPlexSans';
    src: local('IBMPlexSans'), url('IBMPlexSans-Regular.ttf');
}
@font-face {
    font-family: 'TTFirsNeueRegular';
    src: local('TTFirsNeueRegular'), url('TTFirsNeueRegular.ttf');
}

:root {
    font-family: 'IBMPlexSans' !important;
    --w-header: 100px;
    --c-blue: #157ffb;
    --c-platinate-purple: #65274e;
    --c-cambridge-blue: #8eb9a0;
    --c-bone: #e9e8dd;
    --c-green-yellow: #beff3d;
    --v-vivit-violet: #9900f6;
}

.h100 {
    height: 100vh;
}

.ant-message {
    top: 90vh;

    .ant-message-notice-content {
        padding: 0px !important;
        border-radius: 8px;

        .ant-message-custom-content {
            background: #131629;
            color: #ffffff;
            height: 56px;
            min-width: 442px;
            width: 100%;
            max-width: 700px;
            border-radius: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            padding: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .ant-message-error {
            background: #f52245 !important;
        }

        .ant-message-warning {
            background: #ff8200 !important;
        }

        .anticon {
            color: white !important;
        }
    }
}

.ant-tabs-tab {
    user-select: none;
}

@media screen and (max-width: 915px) {
    .h100 {
        height: 100%;
    }

    .ant-message {
        top: 0;

        .ant-message-notice {
            width: 100%;

            .ant-message-notice-content {
                width: 100%;

                .ant-message-custom-content {
                    width: 100%;
                    min-width: 0 !important;
                    height: auto;
                }
            }
        }
    }
}
