.ant-tooltip {
    .ant-tooltip-arrow-content {
        background: #1e253e;
        transform: translateY(-15px) rotate(45deg) !important;
    }

    .ant-tooltip-inner {
        background: #1e253e;
        padding: 4px 8px;
    }
}
