.app-mobile-table {
    .mobile-table-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;

        .mobile-table-skeleton-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            background: #fff;
            border-radius: 8px;
            padding: 16px;
            height: 170px;

            .mobile-table-skeleton {
                .ant-skeleton-title {
                    width: 100% !important;
                }

                .ant-skeleton-paragraph {
                    display: none;
                }
            }
        }
    }
}
