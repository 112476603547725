.app-emptySearch {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #7a829e;
    }
}
