.text-button {
    padding: 0px;
    border: 0px;
    background: transparent;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: var(--c-blue);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
        color: #086ad9;
    }

    &:active {
        color: #00438f !important;
    }
}
