.app-modal {
    max-width: 572px !important;
    width: 100% !important;

    .ant-modal-content {
        background: #ffffff;
        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
            0px 9px 28px 8px rgba(0, 0, 0, 0.05);
        border-radius: 8px;

        .ant-modal-header {
            border-radius: 8px 8px 0 0;
        }
        .ant-modal-footer {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.driver-reg-modal {
    .ant-modal-header {
        margin-left: 7px;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #1e253e;
    }
}
