.page-requests {
  padding: 24px;

  .requests-top {
    display: flex;
    justify-content: flex-start;
    gap: 30px;

    .app-select {
      min-height: 0 !important;
    }

    .requests-search {
      min-width: 500px;
      margin-bottom: 16px;
      min-height: 0;
    }

    .ant-btn {
      color: #9900f6 !important;
    }
  }
}
