.page-users {
    padding: 24px;

    .users-top {
        display: flex;
        justify-content: space-between;

        .app-select {
            min-height: 0 !important;
        }

        .users-search {
            min-width: 500px;
            margin-bottom: 16px;
            min-height: 0;
        }
    }
}
