.page-offers {
  padding: 24px;

  .offers-top {
    display: flex;
    justify-content: space-between;

    .app-select {
      min-height: 0 !important;
    }

    .offers-search {
      min-width: 500px;
      margin-bottom: 16px;
      min-height: 0;
    }
  }
}
