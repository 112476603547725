.app-profile-info {
    padding: 24px;
    margin: 24px;

    .profile-info-title {
        height: 76px;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #4b5167;
        display: flex;
        align-items: center;
    }

    .profile-info-block {
        width: 100%;

        &:not(:last-child) {
            border-bottom: 1px solid #f0f0f0;
        }

        .profile-info-subBlock {
            display: flex;
            align-items: center;
            margin: 16px 0px;

            .profile-info-subtitle {
                display: flex;
                align-items: center;
                width: 20%;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #7a829e;
            }

            .profile-info-data {
                display: flex;
                align-items: center;
                width: 80%;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;

                .status {
                    display: flex;

                    .activated {
                        color: #08b170;
                        margin-right: 3px;
                    }

                    .deactivated {
                        color: #f52245;
                        margin-right: 3px;
                    }

                    .status-date {
                        color: #7a829e;
                    }
                }

                .password {
                    display: flex;

                    .password-date {
                        color: #7a829e;
                    }

                    .password-change {
                        color: var(--c-blue);
                        margin-left: 16px;
                    }
                }
            }
        }
    }

    .profile-skeleton {
        &.short {
            max-width: 200px;
        }

        .ant-skeleton-content {
            h3 {
                width: 40% !important;
                margin-top: 0px !important;
                margin-bottom: 0px !important;
            }

            .ant-skeleton-paragraph {
                display: none;
            }
        }
    }
}
