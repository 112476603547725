.app-checkbox-tag {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .checkbox-tag-input {
        display: none;
    }
    .checkbox-tag-input + .checkbox-tag-label {
        display: flex;
        align-items: center;
        padding: 4px 16px;
        background: #ebeef5;
        border-radius: 100px;
        color: #7a829e;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        white-space: nowrap;
        cursor: pointer;
    }
    .checkbox-tag-input:checked + .checkbox-tag-label {
        color: #ffffff;
        background: var(--c-blue);
    }
}
