.page-promocodes {
  padding: 24px;

  .promocodes-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .promocodes-filters {
        display: inline-flex;
        gap: 16px;
        align-items: center;
    }

    .app-select {
      min-height: 0 !important;
    }

    .promocodes-search {
      min-width: 500px;
      
      min-height: 0;
    }
  }
}

.promocodes-dropdown-shield {
    color: var(--v-vivit-violet);
    display: inline-flex;
    gap: 8px;
    align-items: center;
    height: 32px;
    cursor: pointer;
}