.app-btn {
    left: 0px;
    top: 0px;
    border-radius: 4px !important;
    border: 0px solid transparent !important;
    padding: 5px, 16px, 5px, 16px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .ant-btn-primary {
        &.create-record-button {
            max-width: 155px;
            height: 38px;
        }
    }

    &.default {
        background-color: #ffffff !important;
        border: 1px solid #cfd5e5 !important;
        color: #4b5167 !important;
    }
}

.wide {
    height: 40px !important;
    width: 368px !important;
}
.checkOn {
    height: 38px !important;
    width: 120px !important;
    margin-left: 7px;
    margin-top: 5px;
    margin-bottom: 16px;
}
