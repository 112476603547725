.page-header-mobile {
    display: none !important;
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--w-header) !important;
    padding: 24px !important;
    background: #ffffff !important;

    .header-text-part {
        display: flex;
        flex-direction: column;

        .header-routes {
            display: flex;
            flex-direction: row;
            gap: 4px;
        }

        .header-back {
            display: flex;
            align-items: center;
            color: var(--c-blue);
        }

        .header-text {
            display: flex;
            align-items: center;

            .header-skeleton {
                width: 500px;

                .ant-skeleton-content {
                    h3 {
                        width: 40% !important;
                        margin-top: 0px !important;
                        margin-bottom: 0px !important;
                    }

                    .ant-skeleton-paragraph {
                        display: none;
                    }
                }
            }

            .header-title {
                font-weight: 800;
                font-size: 28px;
                line-height: 36px;
                color: #1e253e;
            }

            .header-total {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #b8bdcc;
                margin-left: 12px;
                margin-top: 9px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .page-header {
        display: none;
    }

    .page-header-mobile {
        // удалить лишнее
        display: flex !important;
        padding: 30px 16px 20px !important;
        background: #f8f9fc !important;

        .header-text-part {
            .header-title {
                font-size: 22px !important;
            }

            .header-text {
                .header-total {
                    margin-left: 10px;
                    margin-top: 5px;
                }
            }
        }

        .app-btn {
            display: none !important;
        }
    }

    .page-records {
        padding-top: 0 !important;

        .records-top {
            .records-search {
                width: 100%;
            }

            .app-select {
                display: none;
            }
        }
    }
}
