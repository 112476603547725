.page-events {
    padding: 24px;

    .events-top {
        display: flex;
        justify-content: space-between;

        .app-select {
            min-height: 0 !important;
        }

        .events-search {
            min-width: 500px;
            margin-bottom: 16px;
            min-height: 0;
        }
    }
}
