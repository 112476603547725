.available {
  color: #08b170;
}

.nonAvailable {
  color: #f52245;
}

.cell {
    display: flex;
    justify-content: space-between;
}

.icon, .cell-interactive {
    cursor: pointer;
}